const {
  ArtAndCultureFeature,
  AttractionsFeature,
  BeachesFeature,
  BikeFeature,
  BudgetFeature,
  CarFeature,
  CompassFeature,
  CutleryFeature,
  LeafFeature,
  RomanceFeature,
  WithKidsFeature,
  WorldPinFeature,
} = require("@icons");
const { ElsewhereIcon } = require("@icons/logos");

module.exports = {
  navbar: [
    {
      title: "Destinations",
      cta: {
        url: "https://ninetoy.com/places",
        title: "See all destinations",
      },
      featured: {
        title: "Best in Travel",
        description:
          "The 30 best countries, cities and regions to visit in 2025",
        url: "https://ninetoy.com/best-in-travel",
        image:
          "https://lonelyplanetstatic.imgix.net/marketing/2025/global_nav/best-in-travel-2025-thumbnail.jpg",
      },
      submenu: {
        items: [
          {
            title: "Africa",
            url: "https://ninetoy.com/africa/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Egypt",
                    url: "https://ninetoy.com/destinations/egypt",
                  },
                  {
                    title: "Ghana",
                    url: "https://ninetoy.com/destinations/ghana",
                  },
                  {
                    title: "Kenya",
                    url: "https://ninetoy.com/destinations/kenya",
                  },
                  {
                    title: "Madagascar",
                    url: "https://ninetoy.com/destinations/madagascar",
                  },
                  {
                    title: "Mauritius",
                    url: "https://ninetoy.com/destinations/mauritius",
                  },
                  {
                    title: "Morocco",
                    url: "https://ninetoy.com/destinations/morocco",
                  },
                  {
                    title: "Namibia",
                    url: "https://ninetoy.com/destinations/namibia",
                  },
                  {
                    title: "South Africa",
                    url: "https://ninetoy.com/destinations/south-africa",
                  },
                  {
                    title: "Tanzania",
                    url: "https://ninetoy.com/destinations/tanzania",
                  },
                ],
              },
              {
                regions: [],
              },
              {
                cities: [
                  {
                    title: "Cairo",
                    url: "https://ninetoy.com/destinations/egypt/cairo",
                  },
                  {
                    title: "Cape Town",
                    url: "https://ninetoy.com/destinations/south-africa/cape-town",
                  },
                  {
                    title: "Marrakesh",
                    url: "https://ninetoy.com/destinations/morocco/marrakesh",
                  },
                ],
              },
            ],
          },
          {
            title: "Antarctica",
            url: "https://ninetoy.com/antarctica/places",
            descendants: [
              {
                countries: [],
              },
              {
                regions: [
                  {
                    title: "Antarctic Peninsula",
                    url: "https://ninetoy.com/destinations/antarctic-peninsula",
                  },
                ],
              },
              {
                cities: [],
              },
            ],
          },
          {
            title: "Asia",
            url: "https://ninetoy.com/asia/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Cambodia",
                    url: "https://ninetoy.com/destinations/cambodia",
                  },
                  {
                    title: "India",
                    url: "https://ninetoy.com/destinations/india",
                  },
                  {
                    title: "Japan",
                    url: "https://ninetoy.com/destinations/japan",
                  },
                  {
                    title: "Nepal",
                    url: "https://ninetoy.com/destinations/nepal",
                  },
                  {
                    title: "Philippines",
                    url: "https://ninetoy.com/destinations/philippines",
                  },
                  {
                    title: "Singapore",
                    url: "https://ninetoy.com/destinations/singapore",
                  },
                  {
                    title: "South Korea",
                    url: "https://ninetoy.com/destinations/south-korea",
                  },
                  {
                    title: "Sri Lanka",
                    url: "https://ninetoy.com/destinations/sri-lanka",
                  },
                  {
                    title: "Taiwan",
                    url: "https://ninetoy.com/destinations/taiwan",
                  },
                  {
                    title: "Thailand",
                    url: "https://ninetoy.com/destinations/thailand",
                  },
                  {
                    title: "Vietnam",
                    url: "https://ninetoy.com/destinations/vietnam",
                  },
                ],
              },
              {
                regions: [
                  {
                    title: "Bali",
                    url: "https://ninetoy.com/destinations/indonesia/bali",
                  },
                ],
              },
              {
                cities: [
                  {
                    title: "Bangkok",
                    url: "https://ninetoy.com/destinations/thailand/bangkok",
                  },
                  {
                    title: "Delhi",
                    url: "https://ninetoy.com/destinations/india/delhi",
                  },
                  {
                    title: "Goa",
                    url: "https://ninetoy.com/destinations/india/goa",
                  },
                  {
                    title: "Ho Chi Minh City",
                    url: "https://ninetoy.com/destinations/vietnam/ho-chi-minh-city",
                  },
                  {
                    title: "Kyoto",
                    url: "https://ninetoy.com/destinations/japan/kansai/kyoto",
                  },
                  {
                    title: "Seoul",
                    url: "https://ninetoy.com/destinations/south-korea/seoul",
                  },
                  {
                    title: "Taipei",
                    url: "https://ninetoy.com/destinations/taiwan/taipei",
                  },
                  {
                    title: "Tokyo",
                    url: "https://ninetoy.com/destinations/japan/tokyo",
                  },
                ],
              },
            ],
          },
          {
            title: "Australia & the Pacific",
            url: "https://ninetoy.com/pacific/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Australia",
                    url: "https://ninetoy.com/destinations/australia",
                  },
                  {
                    title: "Fiji",
                    url: "https://ninetoy.com/destinations/fiji",
                  },
                  {
                    title: "New Zealand",
                    url: "https://ninetoy.com/destinations/new-zealand",
                  },
                  {
                    title: "Palau",
                    url: "https://ninetoy.com/destinations/palau",
                  },
                  {
                    title: "Samoa",
                    url: "https://ninetoy.com/destinations/samoa",
                  },
                ],
              },
              {
                regions: [],
              },
              {
                cities: [
                  {
                    title: "Auckland",
                    url: "https://ninetoy.com/destinations/new-zealand/auckland-1341384",
                  },
                  {
                    title: "Brisbane",
                    url: "https://ninetoy.com/destinations/australia/queensland/brisbane-1336826",
                  },
                  {
                    title: "Melbourne",
                    url: "https://ninetoy.com/destinations/australia/melbourne",
                  },
                  {
                    title: "Perth",
                    url: "https://ninetoy.com/destinations/australia/western-australia/perth",
                  },
                  {
                    title: "Sydney",
                    url: "https://ninetoy.com/destinations/australia/sydney",
                  },
                  {
                    title: "Tasmania",
                    url: "https://ninetoy.com/destinations/australia/tasmania",
                  },
                ],
              },
            ],
          },
          {
            title: "The Caribbean",
            url: "https://ninetoy.com/caribbean/places",
            descendants: [
              {
                countries: [
                  {
                    title: "The Bahamas",
                    url: "https://ninetoy.com/destinations/the-bahamas",
                  },
                  {
                    title: "Barbados",
                    url: "https://ninetoy.com/destinations/barbados",
                  },
                  {
                    title: "Bermuda",
                    url: "https://ninetoy.com/destinations/bermuda",
                  },
                  {
                    title: "Cuba",
                    url: "https://ninetoy.com/destinations/cuba",
                  },
                  {
                    title: "Dominican Republic",
                    url: "https://ninetoy.com/destinations/dominican-republic",
                  },
                  {
                    title: "Jamaica",
                    url: "https://ninetoy.com/destinations/jamaica",
                  },
                  {
                    title: "Puerto Rico",
                    url: "https://ninetoy.com/destinations/puerto-rico",
                  },
                  {
                    title: "St. Lucia",
                    url: "https://ninetoy.com/destinations/st-lucia",
                  },
                ],
              },
              {
                regions: [],
              },
              {
                cities: [
                  {
                    title: "Havana",
                    url: "https://ninetoy.com/destinations/cuba/havana",
                  },
                  {
                    title: "San Juan",
                    url: "https://ninetoy.com/destinations/puerto-rico/san-juan",
                  },
                ],
              },
            ],
          },
          {
            title: "Central America",
            url: "https://ninetoy.com/central-america/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Belize",
                    url: "https://ninetoy.com/destinations/belize",
                  },
                  {
                    title: "Costa Rica",
                    url: "https://ninetoy.com/destinations/costa-rica",
                  },
                  {
                    title: "El Salvador",
                    url: "https://ninetoy.com/destinations/el-salvador",
                  },
                  {
                    title: "Guatemala",
                    url: "https://ninetoy.com/destinations/guatemala",
                  },
                  {
                    title: "Nicaragua",
                    url: "https://ninetoy.com/destinations/nicaragua",
                  },
                  {
                    title: "Panama",
                    url: "https://ninetoy.com/destinations/panama",
                  },
                ],
              },
              {
                regions: [],
              },
              {
                cities: [
                  {
                    title: "Panama City",
                    url: "https://ninetoy.com/destinations/panama/panama-city",
                  },
                  {
                    title: "San José",
                    url: "https://ninetoy.com/destinations/costa-rica/san-jose",
                  },
                ],
              },
            ],
          },
          {
            title: "Europe",
            url: "https://ninetoy.com/europe/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Croatia",
                    url: "https://ninetoy.com/destinations/croatia",
                  },
                  {
                    title: "Czech Republic",
                    url: "https://ninetoy.com/destinations/czech-republic",
                  },
                  {
                    title: "England",
                    url: "https://ninetoy.com/destinations/england",
                  },
                  {
                    title: "France",
                    url: "https://ninetoy.com/destinations/france",
                  },
                  {
                    title: "Germany",
                    url: "https://ninetoy.com/destinations/germany",
                  },
                  {
                    title: "Greece",
                    url: "https://ninetoy.com/destinations/greece",
                  },
                  {
                    title: "Hungary",
                    url: "https://ninetoy.com/destinations/hungary",
                  },
                  {
                    title: "Iceland",
                    url: "https://ninetoy.com/destinations/iceland",
                  },
                  {
                    title: "Ireland",
                    url: "https://ninetoy.com/destinations/ireland",
                  },
                  {
                    title: "Italy",
                    url: "https://ninetoy.com/destinations/italy",
                  },
                  {
                    title: "Malta",
                    url: "https://ninetoy.com/destinations/malta",
                  },
                  {
                    title: "The Netherlands",
                    url: "https://ninetoy.com/destinations/the-netherlands",
                  },
                  {
                    title: "Portugal",
                    url: "https://ninetoy.com/destinations/portugal",
                  },
                  {
                    title: "Spain",
                    url: "https://ninetoy.com/destinations/spain",
                  },
                ],
              },
              {
                regions: [
                  {
                    title: "Amalfi Coast",
                    url: "https://ninetoy.com/destinations/italy/amalfi-coast",
                  },
                  {
                    title: "Canary Islands",
                    url: "https://ninetoy.com/destinations/canary-islands",
                  },
                  {
                    title: "Puglia",
                    url: "https://ninetoy.com/destinations/italy/puglia",
                  },
                  {
                    title: "Sardinia",
                    url: "https://ninetoy.com/destinations/italy/sardinia",
                  },
                  {
                    title: "Sicily",
                    url: "https://ninetoy.com/destinations/italy/sicily",
                  },
                  {
                    title: "Tuscany",
                    url: "https://ninetoy.com/destinations/italy/tuscany",
                  },
                ],
              },
              {
                cities: [
                  {
                    title: "Athens",
                    url: "https://ninetoy.com/destinations/greece/athens",
                  },
                  {
                    title: "Barcelona",
                    url: "https://ninetoy.com/destinations/spain/barcelona",
                  },
                  {
                    title: "Berlin",
                    url: "https://ninetoy.com/destinations/germany/berlin",
                  },
                  {
                    title: "Budapest",
                    url: "https://ninetoy.com/destinations/hungary/budapest",
                  },
                  {
                    title: "Dublin",
                    url: "https://ninetoy.com/destinations/ireland/dublin",
                  },
                  {
                    title: "Dubrovnik",
                    url: "https://ninetoy.com/destinations/croatia/dubrovnik",
                  },
                  {
                    title: "Lisbon",
                    url: "https://ninetoy.com/destinations/portugal/lisbon",
                  },
                  {
                    title: "London",
                    url: "https://ninetoy.com/destinations/england/london",
                  },
                  {
                    title: "Madrid",
                    url: "https://ninetoy.com/destinations/spain/madrid",
                  },
                  {
                    title: "Paris",
                    url: "https://ninetoy.com/destinations/france/paris",
                  },
                  {
                    title: "Prague",
                    url: "https://ninetoy.com/destinations/czech-republic/prague",
                  },
                  {
                    title: "Reykjavik",
                    url: "https://ninetoy.com/destinations/iceland/reykjavik",
                  },
                  {
                    title: "Rome",
                    url: "https://ninetoy.com/destinations/italy/rome",
                  },
                  {
                    title: "Seville",
                    url: "https://ninetoy.com/destinations/spain/seville",
                  },
                  {
                    title: "Valencia",
                    url: "https://ninetoy.com/destinations/spain/valencia-and-murcia/valencia",
                  },
                  {
                    title: "Venice",
                    url: "https://ninetoy.com/destinations/italy/venice",
                  },
                ],
              },
            ],
          },
          {
            title: "Middle East",
            url: "https://ninetoy.com/middle-east/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Israel",
                    url: "https://ninetoy.com/destinations/israel",
                  },
                  {
                    title: "Jordan",
                    url: "https://ninetoy.com/destinations/jordan",
                  },
                  {
                    title: "Oman",
                    url: "https://ninetoy.com/destinations/oman",
                  },
                  {
                    title: "Pakistan",
                    url: "https://ninetoy.com/destinations/pakistan",
                  },
                  {
                    title: "Qatar",
                    url: "https://ninetoy.com/destinations/qatar",
                  },
                  {
                    title: "Turkey",
                    url: "https://ninetoy.com/destinations/turkey",
                  },
                  {
                    title: "United Arab Emirates",
                    url: "https://ninetoy.com/destinations/united-arab-emirates",
                  },
                ],
              },
              {
                regions: [],
              },
              {
                cities: [
                  {
                    title: "Abu Dhabi",
                    url: "https://ninetoy.com/destinations/united-arab-emirates/abu-dhabi",
                  },
                  {
                    title: "Doha",
                    url: "https://ninetoy.com/destinations/qatar/doha",
                  },
                  {
                    title: "Dubai",
                    url: "https://ninetoy.com/destinations/united-arab-emirates/dubai",
                  },
                  {
                    title: "Istanbul",
                    url: "https://ninetoy.com/destinations/turkey/istanbul",
                  },
                  {
                    title: "Jerusalem",
                    url: "https://ninetoy.com/destinations/israel-and-the-palestinian-territories/jerusalem",
                  },
                  {
                    title: "Petra",
                    url: "https://ninetoy.com/destinations/jordan/petra",
                  },
                  {
                    title: "Tel Aviv",
                    url: "https://ninetoy.com/destinations/israel-and-the-palestinian-territories/mediterranean-coast/tel-aviv",
                  },
                ],
              },
            ],
          },
          {
            title: "North America",
            url: "https://ninetoy.com/north-america/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Canada",
                    url: "https://ninetoy.com/destinations/canada",
                  },
                  {
                    title: "Mexico",
                    url: "https://ninetoy.com/destinations/mexico",
                  },
                  {
                    title: "United States",
                    url: "https://ninetoy.com/destinations/usa",
                  },
                ],
              },
              {
                regions: [
                  {
                    title: "Alaska",
                    url: "https://ninetoy.com/destinations/usa/alaska",
                  },
                  {
                    title: "California",
                    url: "https://ninetoy.com/destinations/usa/california",
                  },
                  {
                    title: "Colorado",
                    url: "https://ninetoy.com/destinations/usa/rocky-mountains/colorado",
                  },
                  {
                    title: "Florida",
                    url: "https://ninetoy.com/destinations/usa/florida",
                  },
                  {
                    title: "Hawaii",
                    url: "https://ninetoy.com/destinations/usa/hawaii",
                  },
                  {
                    title: "Texas",
                    url: "https://ninetoy.com/destinations/usa/texas",
                  },
                ],
              },
              {
                cities: [
                  {
                    title: "Anchorage",
                    url: "https://ninetoy.com/destinations/usa/alaska/anchorage",
                  },
                  {
                    title: "Austin",
                    url: "https://ninetoy.com/destinations/usa/austin",
                  },
                  {
                    title: "Cancun",
                    url: "https://ninetoy.com/destinations/mexico/cancun",
                  },
                  {
                    title: "Denver",
                    url: "https://ninetoy.com/destinations/usa/rocky-mountains/denver",
                  },
                  {
                    title: "Las Vegas",
                    url: "https://ninetoy.com/destinations/usa/las-vegas",
                  },
                  {
                    title: "Los Angeles",
                    url: "https://ninetoy.com/destinations/usa/los-angeles",
                  },
                  {
                    title: "Mexico City",
                    url: "https://ninetoy.com/destinations/mexico/mexico-city",
                  },
                  {
                    title: "Miami",
                    url: "https://ninetoy.com/destinations/usa/miami",
                  },
                  {
                    title: "Montreal",
                    url: "https://ninetoy.com/destinations/canada/montreal",
                  },
                  {
                    title: "New Orleans",
                    url: "https://ninetoy.com/destinations/usa/new-orleans",
                  },
                  {
                    title: "New York City",
                    url: "https://ninetoy.com/destinations/usa/new-york-city",
                  },
                  {
                    title: "Oaxaca City",
                    url: "https://ninetoy.com/destinations/mexico/oaxaca-state/oaxaca",
                  },
                  {
                    title: "Orlando",
                    url: "https://ninetoy.com/destinations/usa/florida/orlando",
                  },
                  {
                    title: "San Diego",
                    url: "https://ninetoy.com/destinations/usa/san-diego",
                  },
                  {
                    title: "San Francisco",
                    url: "https://ninetoy.com/destinations/usa/san-francisco",
                  },
                  {
                    title: "Toronto",
                    url: "https://ninetoy.com/destinations/canada/toronto",
                  },
                  {
                    title: "Tulum",
                    url: "https://ninetoy.com/destinations/mexico/yucatan-peninsula/tulum",
                  },
                  {
                    title: "Vancouver",
                    url: "https://ninetoy.com/destinations/canada/vancouver",
                  },
                  {
                    title: "Washington, D.C.",
                    url: "https://ninetoy.com/destinations/usa/washington-dc",
                  },
                ],
              },
            ],
          },
          {
            title: "South America",
            url: "https://ninetoy.com/south-america/places",
            descendants: [
              {
                countries: [
                  {
                    title: "Argentina",
                    url: "https://ninetoy.com/destinations/argentina",
                  },
                  {
                    title: "Bolivia",
                    url: "https://ninetoy.com/destinations/bolivia",
                  },
                  {
                    title: "Brazil",
                    url: "https://ninetoy.com/destinations/brazil",
                  },
                  {
                    title: "Chile",
                    url: "https://ninetoy.com/destinations/chile",
                  },
                  {
                    title: "Colombia",
                    url: "https://ninetoy.com/destinations/colombia",
                  },
                  {
                    title: "Ecuador",
                    url: "https://ninetoy.com/destinations/ecuador",
                  },
                  {
                    title: "Peru",
                    url: "https://ninetoy.com/destinations/peru",
                  },
                  {
                    title: "Uruguay",
                    url: "https://ninetoy.com/destinations/uruguay",
                  },
                ],
              },
              {
                regions: [
                  {
                    title: "Galapagos Islands",
                    url: "https://ninetoy.com/destinations/ecuador/the-galapagos-islands",
                  },
                  {
                    title: "Patagonia",
                    url: "https://ninetoy.com/destinations/argentina/patagonia",
                  },
                ],
              },
              {
                cities: [
                  {
                    title: "Bogotá",
                    url: "https://ninetoy.com/destinations/colombia/bogota",
                  },
                  {
                    title: "Buenos Aires",
                    url: "https://ninetoy.com/destinations/argentina/buenos-aires",
                  },
                  {
                    title: "Cartagena",
                    url: "https://ninetoy.com/destinations/colombia/caribbean-coast/cartagena",
                  },
                  {
                    title: "Lima",
                    url: "https://ninetoy.com/destinations/peru/lima",
                  },
                  {
                    title: "Medellin",
                    url: "https://ninetoy.com/destinations/colombia/northwest-colombia/medellin",
                  },
                  {
                    title: "Rio de Janeiro",
                    url: "https://ninetoy.com/destinations/brazil/rio-de-janeiro",
                  },
                  {
                    title: "Santiago",
                    url: "https://ninetoy.com/destinations/chile/santiago",
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      title: "Planning",
      tagular: { brand: "elsewhere" },
      cta: {
        title: "See all planning resources",
        url: "https://ninetoy.com/articles/category/planning",
      },
      featured: {
        title: "Unlock the world",
        description: "See where a Lonely Planet Membership takes you",
        url: "https://ninetoy.com/membership",
        image:
          "https://lonelyplanetstatic.imgix.net/marketing/2024/global_nav/feature_planning.png",
      },
      submenu: {
        items: [
          {
            title: "Plan with local experts",
            subtitle: "Plan your trip with Elsewhere, by Lonely Planet",
            url: "https://www.elsewhere.io?utm_source=lonelyplanet&utm_medium=globalnav&utm_campaign=globalnav",
            icon: ElsewhereIcon,
            tagular: {
              event: "product_click",
              brand: "Elsewhere",
              location: "GLOBAL SUB MENU_PLANNING",
            },
          },
          {
            title: "Book tours and activities",
            subtitle: "Book with our partner, GetYourGuide",
            url: "https://www.getyourguide.com/?partner_id=MH9NGR8&cmp=homepage_navigation",
            icon: WorldPinFeature,
            tagular: {
              actionOutcome: "EXTERNALLINK",
            },
          },
        ],
      },
    },
    {
      title: "Articles",
      displayTitle: "Inspiration",
      cta: {
        title: "See all articles",
        url: "https://ninetoy.com/articles",
      },
      featured: {
        title: "Lonely Planet Newsletter",
        description:
          "Subscribe to our weekly newsletters to get the latest travel news, expert advice, and insider recommendations",
        url: "https://ninetoy.com/newsletter",
        image:
          "https://lonelyplanetstatic.imgix.net/marketing/2024/global_nav/feature_get_inspired.png",
      },
      submenu: {
        items: [
          {
            title: "Adventure Travel",
            url: "https://ninetoy.com/articles/category/adventure-travel",
            icon: CompassFeature,
          },
          {
            title: "Art and Culture",
            url: "https://ninetoy.com/articles/category/art-and-culture",
            icon: ArtAndCultureFeature,
          },
          {
            title: "Beaches, Coasts and Islands",
            url: "https://ninetoy.com/articles/category/beaches",
            icon: BeachesFeature,
          },
          {
            title: "Family Holidays",
            url: "https://ninetoy.com/articles/category/family-travel",
            icon: WithKidsFeature,
          },
          {
            title: "Festivals",
            url: "https://ninetoy.com/articles/category/festivals-and-events",
            icon: AttractionsFeature,
          },
          {
            title: "Food and Drink",
            url: "https://ninetoy.com/articles/category/food-and-drink",
            icon: CutleryFeature,
          },
          {
            title: "Honeymoon and Romance",
            url: "https://ninetoy.com/articles/category/romance",
            icon: RomanceFeature,
          },
          {
            title: "Road Trips",
            url: "https://ninetoy.com/articles/category/road-trips",
            icon: CarFeature,
          },
          {
            title: "Sustainable Travel",
            url: "https://ninetoy.com/articles/category/sustainable-travel",
            icon: BikeFeature,
          },
          {
            title: "Travel on a Budget",
            url: "https://ninetoy.com/articles/category/budget-travel",
            icon: BudgetFeature,
          },
          {
            title: "Wildlife and Nature",
            url: "https://ninetoy.com/articles/category/wildlife-and-nature",
            icon: LeafFeature,
          },
        ],
      },
    },
    {
      title: "Shop",
      tagular: {
        brand: "shop",
      },
      cta: {
        title: "Explore all products",
        url: "https://shop.lonelyplanet.com?utm_source=lonelyplanet&utm_campaign=navigation&utm_content=shopall",
        tagular: {
          event: "product_click",
        },
      },
      featured: {
        title: "New releases",
        description: "Every month, we release new books into the wild",
        url: "https://shop.lonelyplanet.com/collections/new-releases?utm_source=lonelyplanet&utm_campaign=navigation&utm_content=newreleases",
        image:
          "https://lonelyplanetstatic.imgix.net/marketing/2025/global_nav/lonely_planet_best_in_travel_book.jpg",
        tagular: {
          event: "product_click",
        },
      },
      submenu: {
        items: [
          {
            title: "Destination guides",
            subtitle:
              "Explore the world with our detailed, insightful guidebooks",
            url: "https://shop.lonelyplanet.com/collections/guidebooks?utm_source=lonelyplanet&utm_campaign=navigation&utm_content=destinationguides",
            image:
              "https://lonelyplanetstatic.imgix.net/marketing/2024/global_nav/shop_1.png",
            tagular: {
              event: "product_click",
            },
          },
          {
            title: "Trending Destinations",
            subtitle: "Stay ahead of the curve with our guidebooks",
            url: "https://shop.lonelyplanet.com/collections/trending-destinations?utm_source=lonelyplanet&utm_campaign=navigation&utm_content=trendingdestinations",
            image:
              "https://lonelyplanetstatic.imgix.net/marketing/2025/global_nav/shop-2.png",
            tagular: {
              event: "product_click",
            },
          },
          {
            title: "Experience Guides",
            subtitle:
              "Uncover exciting new ways to explore iconic destinations",
            url: "https://shop.lonelyplanet.com/collections/experience-guides?utm_source=lonelyplanet&utm_campaign=navigation&utm_content=experienceguides",
            image:
              "https://lonelyplanetstatic.imgix.net/marketing/2025/global_nav/shop-3.png",
            tagular: {
              event: "product_click",
            },
          },
          {
            title: "eBooks",
            subtitle: "Download instantly and read any time",
            url: "https://shop.lonelyplanet.com/collections/ebooks?utm_source=lonelyplanet&utm_campaign=navigation&utm_content=eBooks",
            image:
              "https://lonelyplanetstatic.imgix.net/marketing/2025/global_nav/shop-4.png",
            tagular: {
              event: "product_click",
            },
          },
        ],
      },
    },
  ],
  footer: {
    sections: [
      {
        heading: "Top destinations",
        href: "https://ninetoy.com/places",
        sublinks: [
          {
            label: "New York City",
            href: "https://ninetoy.com/destinations/usa/new-york-city",
          },
          {
            label: "Paris",
            href: "https://ninetoy.com/destinations/france/paris",
          },
          {
            label: "Italy",
            href: "https://ninetoy.com/destinations/italy",
          },
          {
            label: "Costa Rica",
            href: "https://ninetoy.com/destinations/costa-rica",
          },
          {
            label: "Japan",
            href: "https://ninetoy.com/destinations/japan",
          },
          {
            label: "USA",
            href: "https://ninetoy.com/destinations/usa",
          },
          {
            label: "Amsterdam",
            href: "https://ninetoy.com/destinations/the-netherlands/amsterdam",
          },
          {
            label: "Portugal",
            href: "https://ninetoy.com/destinations/portugal",
          },
          {
            label: "Cancún",
            href: "https://ninetoy.com/destinations/mexico/cancun",
          },
          {
            label: "Chicago",
            href: "https://ninetoy.com/destinations/usa/chicago",
          },
          {
            label: "England",
            href: "https://ninetoy.com/destinations/england",
          },
          {
            label: "Tokyo",
            href: "https://ninetoy.com/destinations/japan/tokyo",
          },
          {
            label: "France",
            href: "https://ninetoy.com/destinations/france",
          },
          {
            label: "Thailand",
            href: "https://ninetoy.com/destinations/thailand",
          },
          {
            label: "Ireland",
            href: "https://ninetoy.com/destinations/ireland",
          },
          {
            label: "Rome",
            href: "https://ninetoy.com/destinations/italy/rome",
          },
          {
            label: "London",
            href: "https://ninetoy.com/destinations/england/london",
          },
          {
            label: "Los Angeles",
            href: "https://ninetoy.com/destinations/usa/los-angeles",
          },
          {
            label: "Mexico",
            href: "https://ninetoy.com/destinations/mexico",
          },
          {
            label: "San Francisco",
            href: "https://ninetoy.com/destinations/usa/san-francisco",
          },
          {
            label: "Explore More Destinations",
            href: "https://ninetoy.com/places",
          },
        ],
      },
      {
        heading: "Travel Interests",
        href: "https://ninetoy.com",
        sublinks: [
          {
            label: "Adventure Travel",
            href: "https://ninetoy.com/articles/category/adventure-travel",
          },
          {
            label: "Art and Culture",
            href: "https://ninetoy.com/articles/category/art-and-culture",
          },
          {
            label: "Beaches, Coasts and Islands",
            href: "https://ninetoy.com/articles/category/beaches",
          },
          {
            label: "Family Holidays",
            href: "https://ninetoy.com/articles/category/family-travel",
          },
          {
            label: "Festivals",
            href: "https://ninetoy.com/articles/category/festivals-and-events",
          },
          {
            label: "Food and Drink",
            href: "https://ninetoy.com/articles/category/food-and-drink",
          },
          {
            label: "Honeymoon and Romance",
            href: "https://ninetoy.com/articles/category/romance",
          },
          {
            label: "Road Trips",
            href: "https://ninetoy.com/articles/category/road-trips",
          },
          {
            label: "Sustainable Travel",
            href: "https://ninetoy.com/articles/category/sustainable-travel",
          },
          {
            label: "Travel on a Budget",
            href: "https://ninetoy.com/articles/category/budget-travel",
          },
          {
            label: "Wildlife and Nature",
            href: "https://ninetoy.com/articles/category/wildlife-and-nature",
          },
        ],
      },
      {
        heading: "Shop",
        href: "https://shop.lonelyplanet.com",
        sublinks: [
          {
            label: "Destination Guides",
            href: "https://shop.lonelyplanet.com/collections/guidebooks?utm_source=lonelyplanet&utm_campaign=footer&utm_content=destinationguides",
          },
          {
            label: "Lonely Planet Kids",
            href: "https://shop.lonelyplanet.com/pages/lonely-planet-kids?utm_source=lonelyplanet&utm_campaign=footer&utm_content=lpkids",
          },
          {
            label: "Lonely Planet Shop",
            href: "https://shop.lonelyplanet.com?utm_source=lonelyplanet&utm_campaign=footer&utm_content=lpshop",
          },
          {
            label: "Non-English Guides",
            href: "https://support.lonelyplanet.com/hc/en-us/articles/218157937-General-guidebook-information-?utm_source=lonelyplanet&utm_campaign=footer&utm_content=nonenglishguides#product3",
          },
        ],
      },
      {
        heading: "About Us",
        href: "https://ninetoy.com/about",
        sublinks: [
          {
            label: "About Lonely Planet",
            href: "https://ninetoy.com/about",
          },
          {
            label: "Contact Us",
            href: "https://support.lonelyplanet.com/hc/en-us",
          },
          {
            label: "Trade and Advertising",
            href: "https://ninetoy.com/press-trade-advertising",
          },
          {
            label: "Privacy Policy",
            href: "https://ninetoy.com/legal/privacy-policy",
          },
          {
            label: "Terms and Conditions",
            href: "https://ninetoy.com/legal/website-terms",
          },
          {
            label: "Work For Us",
            href: "https://www.redventures.com/careers/brands/lonely-planet",
          },
          {
            label: "Write For Us",
            href: "https://ninetoy.com/about/contribute",
          },
          {
            label: "Sitemap",
            href: "https://ninetoy.com/sitemaps",
          },
          {
            label: "Consumer Health Data Privacy Policy",
            href: "https://ninetoy.com/consumer-health-data-privacy-policy",
          },
        ],
      },
    ],
    socials: [
      {
        label: "Facebook",
        href: "https://www.facebook.com/lonelyplanet",
      },
      {
        label: "Instagram",
        href: "https://www.instagram.com/lonelyplanet",
      },
      {
        label: "Twitter",
        href: "https://www.twitter.com/lonelyplanet",
      },
      {
        label: "Snapchat",
        href: "https://www.snapchat.com/discover/Lonely_Planet/1978581979",
      },
      {
        label: "YouTube",
        href: "https://www.youtube.com/lonelyplanet",
      },
      {
        label: "Pinterest",
        href: "https://www.pinterest.com/lonelyplanet",
      },
      {
        label: "Flipboard",
        href: "https://flipboard.com/@LonelyPlanet?utm_source=LonelyPlanet&utm_medium=follow&utm_campaign=tools",
      },
    ],
  },
};
